<template>
  <div class="content-box">
    <b-form @submit.prevent="onSubmit">
      <div :class="{ row, error: v$.type.$errors.length }" class="form-group">
        <label for="">{{ $t("exports.type") }}</label>
        <div class="row">
          <div class="col-12">
            <v-select id="h-type" v-model="type" :options="types"/>
            <div
              class="input-errors"
              v-for="error of v$.type.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </div>
        </div>
      </div>

      <div :class="{ error: v$.fromDate.$errors.length }" class="form-group">
        <label for="">{{ $t("exports.fromDate") }}</label>
        <div class="row">
          <div class="col-12">
            <datepicker
              v-model="fromDate"
              :enableTimePicker="false"
              :maxDate="maxDate"
              class="form-input"
              format="dd. MM. yyyy"
              previewFormat="dd. MM. yyyy"
            ></datepicker>
            <div
              class="input-errors"
              v-for="error of v$.fromDate.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </div>
        </div>
      </div>

      <div :class="{ error: v$.toDate.$errors.length }" class="form-group">
        <label for="">{{ $t("exports.toDate") }}</label>
        <div class="row">
          <div class="col-12">
            <datepicker
              v-model="toDate"
              :enableTimePicker="false"
              :maxDate="maxDate"
              class="form-input"
              format="dd. MM. yyyy"
              previewFormat="dd. MM. yyyy"
            ></datepicker>
            <div
              class="input-errors"
              v-for="error of v$.toDate.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import vSelect from "vue-select";
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import Datepicker from "@vuepic/vue-datepicker";
import Toast from "@/components/Toast.vue";
import moment from "moment";
import "@vuepic/vue-datepicker/dist/main.css";
import {useToast} from "vue-toastification";

export default {
  props: {
    navigationObj: {
      type: Object, default() {
      }
    },
    action: {type: String, default: null},
  },
  components: {
    vSelect,
    Datepicker,
  },
  data() {
    const today = new Date();
    return {
      type: "",
      fromDate: new Date(new Date().setDate(today.getDate() - 30)),
      toDate: today,
      maxDate: today,
      types: [],
    };
  },
  mixins: [ListUtils, ResourceUtils],
  created() {
    this.getEnumList("export_type", this.types);
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      type: {required: this.$translateError("required")},
      fromDate: {required: this.$translateError("required")},
      toDate: {required: this.$translateError("required")},
    };
  },
  watch: {
    action() {
      if (this.action) {
        this.onSubmit();
      }
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        this.fromDate.setHours(0);
        this.fromDate.setMinutes(0);
        this.fromDate.setSeconds(0);
        this.fromDate.setMilliseconds(0);

        this.toDate.setHours(23);
        this.toDate.setMinutes(59);
        this.toDate.setSeconds(59);
        this.toDate.setMilliseconds(0);

        const body = {
          type: this.type.id,
          fromDate: moment(this.fromDate).format(), // fromDate: moment(this.fromDate).format('YYYY-MM-DD'),
          toDate: moment(this.toDate).format(),
        };

        this.$Exports.createResource({body})
          .then((response) => {
            if (response.status === 200) {
              const link = document.createElement("a");
              link.href = this.$helper.getUrlPreventBrowserCache(response.data.notCachedUrl);
              link.target = "_blank";
              link.click();
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$helper.showToast(
                useToast(),
                Toast,
                error.response.data["hydra:title"],
                error.response.data["hydra:description"],
                "danger"
              );
            }
          });
      }
      this.$emit("clearAction");
    },
    success() {
      this.$router.push(`/export`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
